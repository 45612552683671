import './home.css';
import * as errorTypes from '@constants/errorType';

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAuthCode } from '@store/auth/authSlice';
import { fetchErrors } from '@store/error/errorSlice';
import { setRedirect } from '@store/search/searchSlice';

import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Controls from '@components/controls/controls';
import ErrorTable from '@components/errorTable/errorTable';
import MainHeader from '@components/mainHeader/mainHeader';
import { useLocation, useSearchParams } from 'react-router-dom';

import config from '../../../config/config';
import { addPreviousPageToken } from '../../store/search/searchSlice';

const queryString = require('query-string');

export function Home(props) {
    const [isLoading, setIsLoading] = useState(true);

    const errorLoadingMessage = useSelector((state) => { return state.error.errorLoadingMessage });
    const globalIsLoading = useSelector((state) => { return state.global.globalIsLoading });
    const previousPageTokens = useSelector((state) => state.search.previousPageTokens);

    const dispatch = useDispatch();

    const location = useLocation();

    let [searchParams, setSearchParams] = useSearchParams();

    const isInitialFetch = useRef(true);

    useEffect(() => {
        var parsedQueryString = queryString.parse(location.search);
        dispatch(setAuthCode(parsedQueryString.code));
        dispatch(setRedirect(parsedQueryString.state));

        const pageToken = searchParams.get('pageToken');

        // For when page is initially loaded with a pageToken searchParam
        if (isInitialFetch.current) {
            isInitialFetch.current = false;

            if (pageToken && previousPageTokens.indexOf(pageToken) === -1) {
                dispatch(addPreviousPageToken(pageToken));
            }
        }

        // TODO: cleanup and consolidate somewhere
        let status = searchParams.get('status');
        let searchLimit = searchParams.get('searchLimit');

        if (!status) {
            status = errorTypes.ERROR;
            searchParams.set('status', status);
            setSearchParams(searchParams);
        }

        if (!searchLimit) {
            searchLimit = config.searchLimit;
            searchParams.set('searchLimit', searchLimit);
            setSearchParams(searchParams);
        }

        // Somewhat yucky way to trigger refetching errors when the query
        // parameters change.
        // TODO: consolidate and cleanup searchParams usage across the repo.
        dispatch(fetchErrors()).then(() => setIsLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, searchParams])

    if (errorLoadingMessage) {
        return <div>Error: {errorLoadingMessage.message}</div>;
    } else if (isLoading) {
        return (
            <div style={{ 'textAlign': 'center' }}>
                <ProgressSpinner />
            </div>
        )
    } else {
        return (
            <div className="Home">
                <div className='container-xxl'>
                    <Row>
                        <Col>
                            <MainHeader></MainHeader>
                        </Col>
                    </Row>
                    <Controls></Controls>
                    <Row className="mt-3">
                        <Col>
                            <ErrorTable></ErrorTable>
                            <Dialog visible={globalIsLoading} closable={false}>
                                <ProgressSpinner />
                            </Dialog>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Home;
