import React, { Component } from 'react';

class MainHeader extends Component {

    constructor() {
        super();
        this.state = {
            
        };
    }

    componentDidMount() {
        
    }

    render() {
        return (
            <h1>CloseSimple Errors</h1>
        );
    }
}

export default MainHeader;