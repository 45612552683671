import React from 'react';
import { createRoot } from 'react-dom/client';
import Home from './app/components/home/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './app/store/main';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const container = document.getElementById('root')
const root = createRoot(container)

root.render((
  <Provider store={store}>
    <BrowserRouter
      forceRefresh={true}>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/pending' element={<Home />} />
        <Route path='/about' element={<About />} />
      </Routes>
    </BrowserRouter>
  </Provider>
));

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
