import { createSlice } from '@reduxjs/toolkit';
import * as sortOrders from '@constants/sortOrders';

export const sortSlice = createSlice({
    name: 'sort',
    initialState: {
        sortOrder: sortOrders.DESCENDING
    },
    reducers: {
        setSortOrder: (state, action) => {
            state.sortOrder = action.payload;
        }
    },
})

// export const toggleOrder = () => async (dispatch, getState) => {
//     let currentErrorType = getState().search.isShowingErrors ? errorTypes.ERROR : errorTypes.PENDING;
//     let url = `${config.errorApiHost}errors?status=${currentErrorType}&searchLimit=${config.searchLimit}&sortOrder=${getState().sort.sortOrder}`;
//     dispatch(fetchErrors(errorTypes.ERROR, url));
// }

export const { setSortOrder } = sortSlice.actions;

export default sortSlice.reducer;
