import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authCode: null,
    },
    reducers: {
        setAuthCode: (state, action) => {
            state.authCode = action.payload;
        }
    }
})

export const { setAuthCode } = authSlice.actions;

export default authSlice.reducer;