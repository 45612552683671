import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
    name: 'error',
    initialState: {
        areAllErrorsChecked: false,
        checkedErrors: [],
        errorLoadingMessage: null,
        errors: [],
        originalErrors: [],
        redirect: null
    },
    reducers: {
        clearAllCheckedErrors: (state) => {
            state.checkedErrors = [];
            state.areAllErrorsChecked = false;
        },
        // pushCheckedError: (state, action) => {
        //     state.checkedErrors.push(action.payload);
        // },
        updateCheckedErrors: (state, action) => {
            state.checkedErrors = action.payload;
        },
        removeCheckedError: (state, action) => {
            let checkedIndex = state.checkedErrors.findIndex((error) => {
                return error === action.payload;
            });

            state.checkedErrors.splice(checkedIndex, 1);
        },
        removeCheckedErrors: (state) => {
            state.checkedErrors.forEach(checkedError => {
                let errorIndex = state.errors.findIndex((error) => {
                    return error.CorrelationId === checkedError;
                });

                state.errors.splice(errorIndex, 1);
            });

            state.checkedErrors = [];
        },
        removeError: (state, action) => {
            let errorIndex = state.errors.findIndex((error) => {
                return error.CorrelationId === action.payload.CorrelationId;
            });

            state.errors.splice(errorIndex, 1);
        },
        selectAllErrors: (state) => {
            state.checkedErrors = state.errors.map(error => error.CorrelationId);
            state.areAllErrorsChecked = true;
        },
        setErrorLoadingMessage: (state, action) => {
            state.errorLoadingMessage = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setOriginalErrors: (state, action) => {
            state.originalErrors = action.payload;
        }
    },
});

export const {
    clearAllCheckedErrors,
    updateCheckedErrors,
    pushCheckedError,
    removeCheckedError,
    removeCheckedErrors,
    removeError,
    selectAllErrors,
    setErrorLoadingMessage,
    setErrors,
    setOriginalErrors
} = errorSlice.actions;

export * from './errorActions';
export default errorSlice.reducer;
