import './resolutionPicker.css';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

export function ResolutionPicker(props) {
    const submitResolution = async () => {
        if (props.onSubmit) {
            props.onSubmit();
        }
    }

    const resolutionFooter = (
        <>
        <Button className="p-button-outlined" onClick={props.onHide}>
            Close
        </Button>
        <Button onClick={submitResolution}>
            Submit
        </Button>
        </>
    );

    const resolutionOptions = [
        {label: 'Resolved', value: "Resolved"},
        {label: 'Permanent Error', value: "PermanentError"}
    ];

    return (
        <Dialog header="Select a resolution" footer={resolutionFooter} visible={props.isShowing} onHide={props.onHide}>
            <Container>
                    <Row>
                        <Col>
                            <div>Set status:</div>
                            <Dropdown value={props.value || resolutionOptions[0].value} options={resolutionOptions} onChange={props.onChange} placeholder="Select a resolution" className="resolution-options"></Dropdown>
                        </Col>
                    </Row>
                </Container>
        </Dialog>
    )
}

export default ResolutionPicker;
