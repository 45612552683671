/* eslint max-len: ["error", { "code": 10000 }] */

import React from 'react';

export default function SpeechBubbleSvg() {
  return (
    <svg className="speech-bubble-svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" version="1.1" width="50px" height="50px">
      <g id="surface1">
        <path d="M 25 4 C 12.328125 4 2 12.878906 2 23.992188 C 2 30.445313 5.53125 36.0625 10.960938 39.730469 C 10.953125 39.945313 10.96875 40.296875 10.683594 41.34375 C 10.335938 42.644531 9.625 44.472656 8.1875 46.535156 L 7.164063 48 L 8.949219 48 C 15.140625 48 18.722656 43.964844 19.277344 43.316406 C 21.121094 43.75 23.019531 43.988281 25 43.988281 C 37.671875 43.988281 48 35.109375 48 23.992188 C 48 12.878906 37.671875 4 25 4 Z " />
      </g>
    </svg>
  );
}
