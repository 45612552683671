import * as errorTypes from '@constants/errorType';
import { setGlobalIsLoading } from '@store/globalSlice';
import { addPreviousPageToken, clearPageTokens, setPageToken } from '../search/searchSlice';
import { setErrors, setErrorLoadingMessage, setOriginalErrors } from './errorSlice';

const config = require('@root/config/config');
const queryString = require('query-string');

function base64_url_encode(data) {
    return btoa(encodeURIComponent(data)).replace(/\//g, '_').replace(/\+/g, '-').replace(/=/, '~');
}

function base64_url_decode(data) {
    return decodeURIComponent(atob(data.replace(/_/g, '/').replace(/-/g, '+').replace(/~/,'=')));
}

const returnJsonWithAuth = async (res, {authCode, redirect}, postAuthCallback) => {
    if (res.status === 401 || res.status === 403) {
        let result = await fetch(config.adminAuthApiHost + "token", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: authCode,
                redirect: window.location.origin,
                clientId: config.cognitoClientId
            }),
            credentials: "include"
        });

        if (result.status === 401) {
            window.location.href = config.tokenUrl + base64_url_encode(window.location.origin + window.location.pathname);
            return;
        }
        if (redirect) {
            window.location.href = base64_url_decode(redirect);
            return;
        }

        if (postAuthCallback) {
            postAuthCallback();
        }

        return;
    }

    return res.json();
}

export const filterCurrentErrorsByKeyword = (keyword) => async (dispatch, getState) => {
    if (!keyword) {
        return;
    }

    keyword = keyword.trim();

    let currentErrors = getState().error.errors;

    let filteredErrors = [];

    currentErrors.forEach(error => {
        if (error.ErrorMessage.indexOf(keyword) >= 0) {
            filteredErrors.push(error);
        }
    ;})

    dispatch(setOriginalErrors(currentErrors));
    dispatch(setErrors(filteredErrors));

    dispatch(setGlobalIsLoading(false));
};

export const navigateToPageToken = () => async (dispatch, getState) => {
    var url = `${config.errorApiHost}errors?status=Error&pageToken=${getState().error.pageToken}&searchLimit=${config.searchLimit}`;
    dispatch(fetchErrors(errorTypes.ERROR, encodeURI(url)));
};

export const nextPage = () => async (dispatch, getState) => {
    dispatch(navigateToPageToken());
};


const appendSearch = (url, key) => {
    var parsedQueryString = queryString.parse(window.location.search);
    if (parsedQueryString[key]) {
        url = url + `&${key}=${encodeURIComponent(parsedQueryString[key])}`
    }

    return url;
}

const createSearchUrl = () => {
    var url = `${config.errorApiHost}errors?`;
    url = appendSearch(url, "status");
    url = appendSearch(url, "searchLimit");
    url = appendSearch(url, "pageToken");
    url = appendSearch(url, "companyId");
    url = appendSearch(url, "orderId");
    url = appendSearch(url, "cs2System");

    return url;
};


export const fetchErrors = (preserveTokens) => async (dispatch, getState) => {
    dispatch(setGlobalIsLoading(true));

    var parsedQueryString = queryString.parse(window.location.search);

    if (!parsedQueryString.pageToken) {
        dispatch(setPageToken(null));
        dispatch(clearPageTokens());
    }

    var searchUrl = createSearchUrl();


    try {
        let authResponse = await fetch(searchUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'AnyVal'
            },
            credentials: "include"
        });

        let authRequestPayload = {
            authCode: getState().auth.authCode,
            redirect: getState().search.redirect
        };

        let jsonResponse = await returnJsonWithAuth(authResponse, authRequestPayload, fetchErrors);
        dispatch(setErrors(jsonResponse.Errors));

        if (jsonResponse.PageToken && jsonResponse.PageToken !== "{}") {
            dispatch(setPageToken(jsonResponse.PageToken));
            dispatch(addPreviousPageToken(jsonResponse.PageToken));
        }
    }

    catch(error) {
        // TODO: look into "Cannot read properties of undefined (reading 'Errors')"
        // happens with cold start? longer FE timeout? AWS thing?
        console.log(error);
        dispatch(setErrorLoadingMessage(error));
    }

    dispatch(setGlobalIsLoading(false));
};

export const submitErrorResolution = async (error, status) => {
    let url = `${config.errorApiHost}error/${error.CorrelationId}/resolve`;
    try {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'AnyVal'
            },
            credentials: "include",
            body: JSON.stringify({
                "Status": status
            })
        });
    }
    catch(error) {
        debugger
    }

    console.log("submit resolution response received");
}

export const resubmitError = async (error) => {
    let url = `${config.errorApiHost}error/${error.CorrelationId}/resubmit`;
    let resubmitResolutionResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'AnyVal'
        },
        credentials: "include"
    });

    const responseJson = await resubmitResolutionResponse.json();
    console.log(responseJson);
    return responseJson;
}

export const bulkResolve = (status) => async (dispatch, getState) => {
    let url = `${config.errorApiHost}error/bulkResolve`;
    let body = {
        Status: status,
        CorrelationIds: []
    };

    getState().error.checkedErrors.forEach(error => {
        body.CorrelationIds.push(error.CorrelationId);
    });

    let bulkResolutionResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'AnyVal'
        },
        credentials: "include",
        body: JSON.stringify(body)
    });

    console.log("bulkResolved response received");
    console.log(bulkResolutionResponse);

    return true;
}

export const sampleFunction = (status) => async (dispatch, getState) => {

    return true;
}

export const bulkResubmit = () => async (dispatch, getState) => {
    let url = `${config.errorApiHost}error/bulkResubmit`;
    let body = {
        CorrelationIds: []
    };

    getState().error.checkedErrors.forEach(error => {
        body.CorrelationIds.push(error.CorrelationId);
    });

    let bulkResolutionResponse = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'AnyVal'
        },
        credentials: "include",
        body: JSON.stringify(body)
    });

    const responseJson = await bulkResolutionResponse.json();

    console.log("bulkResubmit response received");
    console.log(bulkResolutionResponse);
    console.log(responseJson);

    return responseJson;
};
