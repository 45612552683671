import React from 'react';
import newman from '../../assets/newman.GIF';
import SpeechBubbleSvg from '../../assets/speech-bubble-svg';

const defaultMessage = () => (
  <>
    <span>Ah Ah Ah!</span>
    <span>Your query doesn't match any errors.</span>
  </>
);

export const NewmanText = (message, image, className) => (
  <div className={`newman-container ${className}`}>
    <img alt="" src={image || newman} className="ah-ah-ah" />
    <SpeechBubbleSvg />
    <span className="newman-text">{message || defaultMessage()}</span>
  </div>
);
