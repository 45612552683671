import './errorTable.css';

import ResolutionPicker from '@components/resolutionPicker/resolutionPicker';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCheckedErrors, removeError, resubmitError, submitErrorResolution } from '@store/error/errorSlice';
import { setGlobalIsLoading } from '@store/globalSlice';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { AutoComplete } from 'primereact/autocomplete';
import { clearAllCheckedErrors } from '../../store/error/errorSlice';
import { useSearchParams } from 'react-router-dom';
import _ from 'underscore';
import { Dialog } from 'primereact/dialog';
import { NewmanText } from '../newman/newman-text';

const companies = require('@constants/companyLists/companies').companies;
const config = require('@root/config/config');
const moment = require('moment');


const defaultErrorMessage = 'An unknown error occurred'

const getCompanyNameFromId = (companyId) => {
    const company = companies.find(company => company.CompanyId === companyId);
    return company ? company.Name : '';
};

const getCompanyIdFromName = (companyName) => {
    const company = companies.find(company => company.Name === companyName);
    return company ? company.CompanyId : '';
};

const getDateTimeString = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
};

const isValidJson = (s) => {
    try {
        JSON.parse(s);
    } catch (e) {
        return false;
    }
    return true;
};

const truncateErrorMessage = (errorMessage) => {
    return errorMessage.length > 300 ? `${errorMessage.substring(0, 300)}...` : errorMessage;
}

export function ErrorTable() {
    const [activeResolutionError, setActiveResolutionError] = useState(null);
    const [isResolutionDialogShowing, setIsResolutionDialogShowing] = useState(false);
    const [selectedResolutionOption, setSelectedResolutionOption] = useState("");
    const checkedErrors = useSelector((state) => state.error.checkedErrors);
    const items = useSelector((state) => state.error.errors);
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    const defaultCompanyFilter = getCompanyNameFromId(searchParams.get('companyId')) || searchParams.get('companyId') || '';
    const [companyFilter, setCompanyFilter] = useState(defaultCompanyFilter);
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const [orderFilter, setOrderFilter] = useState(searchParams.get('orderId') || '')
    const [expandedRows, setExpandedRows] = useState(null);
    const [resubmitFailVisible, setResubmitFailVisible] = useState(false);
    const [resubmitFailContent, setResubmitFailContent] = useState(defaultErrorMessage);

    FilterService.register('doNothing', (a, b) => {
        return true;
    });

    const [filters, setFilters] = useState({
        'company': { value: searchParams.get('companyId'), matchMode: 'doNothing', },
        'orderId': { value: searchParams.get('orderId'), matchMode: 'doNothing' },
        'ErrorMessage': { value: '', matchMode: FilterMatchMode.CONTAINS },
    });

    useEffect(() => {
        dispatch(clearAllCheckedErrors());
    }, [items, dispatch]);


    useEffect(() => {
        setCompanyFilter(defaultCompanyFilter);
        setOrderFilter(searchParams.get('orderId') || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);


    const filterByCompany = (company) => {
        let newCompanyName;
        let newCompanyId;

        if (company) {
            newCompanyName = company.Name || company;
            newCompanyId = company.CompanyId || getCompanyIdFromName(newCompanyName) || company;
            setCompanyFilter(newCompanyName);
        }

        searchParams.set('companyId', newCompanyId || getCompanyIdFromName(companyFilter) || companyFilter);
        searchParams.delete('pageToken');
        setSearchParams(searchParams);
    };

    const filterByOrder = () => {
        searchParams.set('orderId', orderFilter);
        searchParams.delete('pageToken');
        setSearchParams(searchParams);
    };

    const clearCompanyFilter = () => {
        searchParams.delete('companyId');
        searchParams.delete('pageToken');
        setSearchParams(searchParams);
        setCompanyFilter('');
    };

    const clearOrderFilter = () => {
        searchParams.delete('orderId');
        searchParams.delete('pageToken');
        setSearchParams(searchParams);
        setOrderFilter('');
    };

    const clearAllFilters = () => {
        searchParams.delete('companyId');
        searchParams.delete('orderId');
        searchParams.delete('pageToken');

        // This is only needed for resetting the FE error filter
        setFilters({
            'company': { value: '', matchMode: 'doNothing', },
            'orderId': { value: '', matchMode: 'doNothing' },
            'ErrorMessage': { value: '', matchMode: FilterMatchMode.CONTAINS },
        })

        setCompanyFilter('');
        setOrderFilter('');

        setSearchParams(searchParams);
    };

    const closeResolutionDialog = () => {
        setActiveResolutionError(null);
        setIsResolutionDialogShowing(false);
        setSelectedResolutionOption('');
    }

    const submitResolution = async () => {
        dispatch(setGlobalIsLoading(true));
        await submitErrorResolution(activeResolutionError, selectedResolutionOption);
        dispatch(setGlobalIsLoading(false));

        dispatch(removeError(activeResolutionError));

        closeResolutionDialog();
    }

    const onSelectionChange = (e) => {
        dispatch(updateCheckedErrors(e.value));
    }

    // Uncheck errors that get hidden by front-end filters. This also fires for
    // sorting so there's some extra overhead.
    const updateSelection = (visibleErrors) => {
        const visibleCheckedErrors = checkedErrors.filter((error) => {
            return _.findWhere(visibleErrors, {CorrelationId: error.CorrelationId});
        });
        dispatch(updateCheckedErrors(visibleCheckedErrors));
    }

    const showResolveDialog = (error) => {
        setActiveResolutionError(error);
        setIsResolutionDialogShowing(true);
    };

    const resubmit = async (error) => {
        dispatch(setGlobalIsLoading(true));
        const response = await resubmitError(error);
        if (response.url) {
            window.open(response.url);
            dispatch(removeError(error));
        } else {
            if (response.message) setResubmitFailContent(`Error message: ${response.message}`)
            setResubmitFailVisible(true);
            console.log(response);
        }

        dispatch(setGlobalIsLoading(false));
    };

    const tooltipOptions = {
        showDelay: 500
    };

    const actionTemplate = (error) => (
        <>
            <Button className="p-button p-component p-button-icon-only p-button-outlined p-button-sm" icon="pi pi-check" style={{ marginRight: '4px' }} tooltip="Resolve" tooltipOptions={tooltipOptions} onClick={() => { showResolveDialog(error) }} />
            <Button className="p-button p-component p-button-icon-only p-button-outlined p-button-sm" icon="pi pi-arrow-circle-right" style={{ marginRight: '4px' }} tooltip="Resubmit" tooltipOptions={tooltipOptions} onClick={() => { resubmit(error) }} />
        </>
    );

    const companyTemplate = (error) => {
        let company = getCompanyNameFromId(error.CompanyId) || error.CompanyId;

        return (
            <>
                {/* TODO: Tooltips repeat on every row because they all have the
                    same class. Need to differentiate target.
                 */}
                {/* <Tooltip target=".error-row-company" content={error.CompanyId} showDelay={1000} /> */}
                <a href={config.getCompanyIdLink(error)} rel='noreferrer' target="_blank"><span>{company}</span></a>
            </>
        );
    };

    const orderIdTemplate = (error) => (
        <a href={config.getOrderIdLink(error)} rel='noreferrer' target="_blank"><span>{error.OrderId}</span></a>
    );

    const correlationIdTemplate = (error) => (
        <a href={error.ExecutionUrl} rel='noreferrer' target="_blank"><span>{error.CorrelationId}</span></a>
    );

    const createdTemplate = (error) => {
        return (
            <>
                {/* <Tooltip target=".error-row-created-at" content={error.CreatedDate} showDelay={500} autoHide={false} /> */}
                <span>{moment(error.CreatedDate).fromNow()}</span>
                <span>{getDateTimeString(error.CreatedDate)}</span>
                <span>{error.CreatedDate}</span>
            </>
        );
    };

    const errorTemplate = (error) => {
        if (error.ErrorMessage && isValidJson(error.ErrorMessage)) {
            return truncateErrorMessage(JSON.parse(error.ErrorMessage).errorMessage || '');
        }

        return truncateErrorMessage(error.ErrorMessage || '');
    };

    const rowExpansionTemplate = (error) => {
        return (
            <div >
                <DataTable
                    // showGridlines={false}
                    value={[error]}
                    headerStyle={{ padding: '0'}}
                    tableStyle={{ width: '100%' }}
                >
                    <Column
                        header="System"
                        field="CS2System"
                    />
                    <Column
                        header="Modified By"
                        field="ModifiedBy"
                    />
                    <Column
                        header="Modified Date"
                        field="ModifiedDate"
                        body={(err) => `${moment(err.ModifiedDate).fromNow()} (${getDateTimeString(err.ModifiedDate)})`}
                        style={{ minWidth: '350px', flexDirection: "column", alignItems: 'start' }}
                    />
                    <Column
                        header="Retries"
                        field="RetryCount"
                    />
                    <Column
                        header="Files"
                        body={(err) => err.CacheFiles.length}
                    />
                </DataTable>
                <span>{error.ErrorMessage}</span>
            </div>
        );
    };


    const updateFilteredCompanies = (e) => {
        if (!e.query) setFilteredCompanies(companies);

        setFilteredCompanies(companies.filter((company) => {
            const matchesId = company.CompanyId.toLowerCase().includes(e.query.toLowerCase());
            const matchesName = company.Name.toLowerCase().includes(e.query.toLowerCase())
            return matchesId || matchesName;
        }));
    };

    const companyFilterElement = () => {
        return (
            <div className="p-column-filter p-fluid p-column-filter-row" style={{ minWidth: '250px' }}>
                <AutoComplete
                    value={companyFilter}
                    suggestions={filteredCompanies}
                    field='Name'
                    placeholder='Search by Company'
                    completeMethod={updateFilteredCompanies}
                    onChange={(e) => setCompanyFilter(e.value.Name || getCompanyNameFromId(e.value) || e.value)}
                    onSelect={(e) => filterByCompany(e.value)}
                    dropdown
                    dropdownMode='current'
                />
                <button
                    type="button" className="p-column-filter-menu-button p-link p-column-filter-menu-button-active"
                    onClick={() => filterByCompany()}
                    disabled={(!companyFilter && !searchParams.get('companyId'))}
                >
                    <span className="pi pi-filter-icon pi-filter"></span>
                </button>
                {(searchParams.get('companyId')) &&
                    <button className="p-column-filter-clear-button p-link" type="button"
                        onClick={() => clearCompanyFilter()}
                    >
                        <span className="pi pi-filter-slash"></span>
                    </button>
                }
            </div>
        );
    }

    const orderFilterElement = () => (
        <div className="p-column-filter p-fluid p-column-filter-row" style={{ minWidth: '250px' }}>
            <div className="p-fluid p-column-filter-element" style={{ maxWidth: '170px', width: '170px' }}>
                <input type="text" className="p-inputtext p-component p-filled p-column-filter"
                    placeholder="Search by Order ID"
                    value={orderFilter} onChange={(e) => setOrderFilter(e.target.value)}
                />
            </div>
            <button
                type="button" className="p-column-filter-menu-button p-link p-column-filter-menu-button-active"
                onClick={() => filterByOrder()}
                disabled={(!orderFilter && !searchParams.get('orderId'))}
            >
                <span className="pi pi-filter-icon pi-filter"></span>
            </button>
            {(searchParams.get('orderId')) &&
                <button className="p-column-filter-clear-button p-link" type="button"
                    onClick={() => clearOrderFilter()}
                >
                    <span className="pi pi-filter-slash"></span>
                </button>
            }
        </div>
    );

    const toggleExpandAll = () => {
        const allExpanded = expandedRows && expandedRows.length === items.length;
        if (allExpanded) {
            setExpandedRows(null);
        } else {
            setExpandedRows([...items])
        }
    }

    const expandAllFilterRowToggleElement = () => {
        const allExpanded = expandedRows && expandedRows.length === items.length;
        return (
            <Button
                className="p-button-rounded"
                icon={allExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'}
                onClick={toggleExpandAll}
            ></Button>
        )
    }

    const actionsRowFilterRowElement = () => {
        return (
            <Button
                icon="pi pi-filter-slash"
                className="p-button-rounded"
                onClick={clearAllFilters}
                tooltip='Clear all filters'
                tooltipOptions={tooltipOptions}
            />
        )
    }


    return (
        <div className="card" style={{ height: 'calc(100vh - 145px)' }}>
            <DataTable
                value={items}
                // showGridlines
                stripedRows
                scrollable
                scrollHeight="flex"
                responsiveLayout="scroll"
                filterDisplay="row"
                filters={filters}
                className="error-table p-datatable-sm"
                selection={checkedErrors}
                onSelectionChange={onSelectionChange}
                selectionMode="checkbox"
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                rowExpansionTemplate={rowExpansionTemplate}
                onValueChange={updateSelection}
                emptyMessage={NewmanText()}
            >
                <Column selectionMode="multiple" field="selected" style={{ maxWidth: '40px' }}></Column>

                <Column
                    header="Company"
                    body={companyTemplate}
                    placeholder="Search"
                    sortable
                    sortField="CompanyId"
                    className="error-row-company"
                    filter
                    filterElement={companyFilterElement}
                    showFilterMenu={false}
                    style={{ minWidth: '265px' }}
                />
                <Column
                    header="Order ID"
                    body={orderIdTemplate}
                    field="OrderId"
                    sortable
                    sortField="OrderId"
                    filter
                    filterElement={orderFilterElement}
                    showFilterMenu={false}
                    style={{ minWidth: '265px' }}
                />
                <Column
                    header="Correlation ID"
                    body={correlationIdTemplate}
                    field="CorrelationId"
                    sortable
                    sortField="CorrelationId"
                    style={{ maxWidth: '130px', width: '130px' }}
                />
                <Column header="Error"
                    body={errorTemplate}
                    field="ErrorMessage"
                    filter
                    showFilterMenu={false}
                    filterPlaceholder="Filter by Error"
                    sortable
                    sortField="ErrorMessage"
                    style={{ minWidth: '250px' }}
                />
                <Column
                    header="Created"
                    body={createdTemplate}
                    sortable
                    sortField="CreatedDate"
                    className="error-row-created-at"
                    style={{ minWidth: '120px', flexDirection: "column", alignItems: 'start' }}
                />
                <Column
                    header="Actions"
                    body={actionTemplate}
                    style={{ textAlign: 'center', maxWidth: '85px', width: '85px' }}
                    filter
                    filterElement={actionsRowFilterRowElement}
                    showFilterMenu={false}
                    showClearButton={false}
                />

                <Column
                    header="More"
                    expander
                    style={{ maxWidth: '44px', width: '44px' }}
                    filter
                    filterElement={expandAllFilterRowToggleElement}
                    showFilterMenu={false}
                    showClearButton={false}
                />
            </DataTable>

            <Dialog
                header="Failed to Resubmit"
                visible={resubmitFailVisible}
                onHide={() => {
                    setResubmitFailVisible(false);
                    setResubmitFailContent(defaultErrorMessage);
                }}
            >{resubmitFailContent}</Dialog>

            {
                activeResolutionError &&
                <ResolutionPicker value={selectedResolutionOption} isShowing={isResolutionDialogShowing} onHide={closeResolutionDialog} onChange={($event) => { setSelectedResolutionOption($event.value) }} onSubmit={submitResolution}></ResolutionPicker>
            }
        </div>
    );
}

export default ErrorTable;
