import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import errorReducer from './error/errorSlice';
import globalReducer from './globalSlice';
import searchReducer from './search/searchSlice';
import sortSlice from './sort/sortSlice';

export default configureStore({
    reducer: {
        auth: authReducer,
        error: errorReducer,
        global: globalReducer,
        search: searchReducer,
        sort: sortSlice
    }
});
