import { createSlice } from '@reduxjs/toolkit'

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    errors: [],
    globalIsLoading: false
  },
  reducers: {
      setErrors: (state, action) => {
        state.errors = action.payload;
      },
      setGlobalIsLoading: (state, action) => {
        state.globalIsLoading = action.payload;
      }
  },
})

export const { setErrors, setGlobalIsLoading } = globalSlice.actions;

export default globalSlice.reducer;