import './controls.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    bulkResolve,
    bulkResubmit,
    clearAllCheckedErrors,
    removeCheckedErrors,
    fetchErrors,
} from '@store/error/errorSlice';

import * as errorTypes from '@constants/errorType';

import { setGlobalIsLoading } from '@store/globalSlice';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { SplitButton } from 'primereact/splitbutton';
import { Fragment, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ResolutionPicker from '@components/resolutionPicker/resolutionPicker';
import { useSearchParams } from "react-router-dom";
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import useLocalStorage from '../../hooks/useLocalStorage';
import { usePageVisibility } from '../../hooks/usePageVisibility';
import { usePrevious } from '../../hooks/usePrevious';
import { Paginator } from 'primereact/paginator';
import config from '../../../config/config';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'underscore';

const companies = require('@constants/companyLists/companies').companies;


const getCompanyNameFromId = (companyId) => {
    const company = companies.find(company => company.CompanyId === companyId);
    return company ? company.Name : '';
};

export function Controls() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [searchLimit, setSearchLimit] = useState(searchParams.get('searchLimit'));
    const [searchLimitOptions, setSearchLimitOptions] = useState([
        {label: '20', value: '20'},
        {label: '200', value: '200'},
        {label: '1000', value: '1000'},
        {label: '5000', value: '5000'},
        {label: '10000', value: '10000'},
    ]);

    // Add a new custom option if the searchParam has a unique val for searchLimit
    if (!_.findWhere(searchLimitOptions, ({ value: searchLimit }))) {
        setSearchLimitOptions((prev) => [{ label: searchLimit, value: searchLimit }, ...prev]);
    }

    const checkedErrors = useSelector((state) => state.error.checkedErrors);
    const [statusFilter, setStatusFilter] = useState(searchParams.get('status') || errorTypes.ERROR);

    const previousPageTokens = useSelector((state) => state.search.previousPageTokens);

    const [pollingFreq, setPollingFreq] = useLocalStorage('pollingFreq', 0);
    const isVisible = usePageVisibility();
    const prevIsVisible = usePrevious(isVisible);

    const dispatch = useDispatch();

    const [isShowingResolutionDialog, setIsShowingResolutionDialog] = useState(false);

    const [selectedResolutionOption, setSelectedResolutionOption] = useState("");


    const [resubmitDialogVisible, setResubmitDialogVisible] = useState(false);
    const [resubmitHeaderContent, setResubmitHeaderContent] = useState('');
    const [resubmitDialogContent, setResubmitDialogContent] = useState('');


    useEffect(() => {
        setSearchLimit(searchParams.get('searchLimit'))
    }, [searchParams]);


    // Clearing the page token because that makes sense when refreshing errors.
    // conveniently/hackily this has the side effect of triggering
    // #fetchErrors in home.js. Refetch from here if no pageToken to remove.
    const refreshErrors = () => {
        if (searchParams.get('pageToken')) {
            searchParams.delete('pageToken');
            setSearchParams(searchParams);
        } else {
            dispatch(fetchErrors());
        }
    };

    useEffect(() => {
        if (!pollingFreq || !isVisible) return;

        // If polling is on, refetch when page goes from not visible to visible.
        if (prevIsVisible === false) {
            refreshErrors();
        }

        const pollingInterval = setInterval(() => {
            refreshErrors()
        }, pollingFreq);

        return () => clearInterval(pollingInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollingFreq, dispatch, isVisible, prevIsVisible]);


    const filterByStatus = (status) => {
        if (!status) return;
        setStatusFilter(status);
        searchParams.set('status', status);
        searchParams.delete('pageToken');
        setSearchParams(searchParams);
    };

    const setSearchLimitFromDropdown = (limit) => {
        setSearchLimit(limit);
        searchParams.set('searchLimit', limit)
        searchParams.delete('pageToken');
        setSearchParams(searchParams);
    };

    const bulkResubmitSuccessTemplate = () => {
        return (
            <DataTable
                value={checkedErrors}
                showGridlines={false}
                headerStyle={{ padding: '0'}}
                bodyStyle={{border: 'none'}}
                scrollable
                scrollHeight='400px'
            >
                <Column
                    header="Company"
                    field="CompanyId"
                    body={(error) => {
                        let company = getCompanyNameFromId(error.CompanyId) || error.CompanyId;
                        return (<a href={config.getCompanyIdLink(error)} rel='noreferrer' target="_blank"><span>{company}</span></a>)
                    }}
                    />
                <Column
                    header="Correlation ID (original error)"
                    field="CorrelationId"
                    body={(error) => {
                        return (<a href={error.ExecutionUrl} rel='noreferrer' target="_blank"><span>{error.CorrelationId}</span></a>)
                    }}
                />
            </DataTable>
        );
    };

    const submitSelected = async () => {
            dispatch(setGlobalIsLoading(true));
            const response = await dispatch(bulkResubmit());

            if (response.urls) {
                setResubmitHeaderContent('Resubmitted Errors')
                setResubmitDialogContent(bulkResubmitSuccessTemplate())

                dispatch(clearAllCheckedErrors());
            } else {
                setResubmitHeaderContent('Potential Errors During Bulk Resubmission')
                setResubmitDialogContent(`Resubmitted errors will remain checked. Request response: ${response}`)
            }

            setResubmitDialogVisible(true);

            dispatch(setGlobalIsLoading(false));
    };

    const toggleChecksContextOptions = [
        {
            label: `Resolve ${checkedErrors.length} Checked`,
            icon: 'pi pi-check',
            command: ($event) => {
                setIsShowingResolutionDialog(true);
            },
        },
    ];

    const closeResolutionPicker = () => {
        setIsShowingResolutionDialog(false);
    }

    const submit = async () => {
        dispatch(setGlobalIsLoading(true));
        await dispatch(bulkResolve(selectedResolutionOption));
        dispatch(removeCheckedErrors());
        dispatch(setGlobalIsLoading(false));
        setIsShowingResolutionDialog(false);
    };

    const tooltipOptions = {
        showDelay: 500,
        position: 'top'
    };

    const pageToken = searchParams.get('pageToken');
    const currentPageIndex = previousPageTokens.indexOf(pageToken);

    const page = currentPageIndex >= 0 ? currentPageIndex + 1 : 0;
    const totalPages = previousPageTokens.length + 1;

    const onPageChange = (event) => {
        const newToken = previousPageTokens[event.page - 1]

        newToken ? searchParams.set('pageToken', newToken) : searchParams.delete('pageToken');
        setSearchParams(searchParams);
    }


    return (
        <Fragment>
            <Row>
                <Col className="controls-container">
                    <div className="controls-left">
                        <span>
                            <Dropdown
                                value={searchLimit}
                                options={searchLimitOptions}
                                onChange={(e) => setSearchLimitFromDropdown(e.value)}
                                optionLabel='label'
                                optionValue='value'
                                tooltip='Max errors per page'
                                tooltipOptions={{ showDelay: 500 }}
                            />
                        </span>
                        <SelectButton value={statusFilter} options={[errorTypes.ERROR, errorTypes.PENDING]} onChange={(e) => filterByStatus(e.value)} />
                    </div>

                    <div className="controls-center">
                        <Paginator first={page} rows={1} totalRecords={totalPages} onPageChange={onPageChange}
                            currentPageReportTemplate={`{currentPage} of {totalPages}${totalPages > (page + 1) ? '+' : ''}`}
                            template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">
                        </Paginator>
                    </div>

                    <div className="controls-right">
                        <SplitButton className="mr-1 p-button-sm" menuClassName="p-button-sm" label={`Resubmit Checked (${checkedErrors.length})`} onClick={submitSelected} model={toggleChecksContextOptions} disabled={!checkedErrors.length}/>
                        <span className="p-buttonset">
                            <Button icon="pi pi-refresh" onClick={() => { refreshErrors() }} tooltip="Refresh errors" tooltipOptions={tooltipOptions} style={{ borderRadius: '3px 0 0 3px' }}/>
                            <Dropdown
                                options={[
                                    {label: 'Off', value: 0},
                                    {label: '10s', value: 10 * 1000},
                                    {label: '1m', value: 60 * 1000},
                                    {label: '5m', value: 5 * 60 * 1000},
                                    {label: '15m', value: 15 * 60 * 1000},
                                ]}
                                optionLabel='label'
                                optionValue='value'
                                value={pollingFreq}
                                onChange={(e) => setPollingFreq(e.value)}
                                tooltip='Auto refresh frequency'
                                tooltipOptions={tooltipOptions}
                            />
                        </span>
                    </div>
                </Col>
            </Row>

            <Dialog
                header={resubmitHeaderContent}
                visible={resubmitDialogVisible}
                onHide={() => {
                    setResubmitDialogVisible(false);
                    setResubmitDialogContent('');
                }}
            >{resubmitDialogContent}</Dialog>

            {
                <ResolutionPicker value={selectedResolutionOption} onChange={($event) => {setSelectedResolutionOption($event.value)}} onSubmit={submit} isShowing={isShowingResolutionDialog} onHide={closeResolutionPicker}></ResolutionPicker>
            }
        </Fragment>
    );
}

export default Controls;
