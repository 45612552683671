import { createSlice } from '@reduxjs/toolkit'
import _ from 'underscore';

const config = require('@root/config/config');

const queryString = require('query-string');


export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        companyId: null,
        orderId: null,
        isSearchModalOpen: false,
        isShowingErrors: window.location.href.toLowerCase().indexOf("pending") < 0,
        parsedQueryString: null,
        redirect: null,
        searchLimit: config.searchLimit,
        pageToken: null,
        previousPageTokens: [],
    },
    reducers: {
        setPageToken: (state, action) => {
            state.pageToken = action.payload;
        },
        addPreviousPageToken: (state, action) => {
            const parsedQueryString = queryString.parse(window.location.search);
            const currentToken = parsedQueryString.pageToken;
            const currentTokenIndex = state.previousPageTokens.indexOf(currentToken);

            // When paging back remove tokens after the current to avoid hanging
            // onto unwanted/stale tokens (e.g. after resolving, tokens will have changed)
            if (currentTokenIndex !== -1 && currentToken !== _.last(state.previousPageTokens)) {
                state.previousPageTokens.splice(currentTokenIndex + 1);
            }

            if (state.previousPageTokens.indexOf(action.payload) === -1) {
                state.previousPageTokens.push(action.payload);
            }
        },
        clearPageTokens: (state) => {
            state.previousPageTokens = [];
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload;
        },
        setIsShowingErrors: (state, action) => {
            state.isShowingErrors = action.payload;
        },
        setStatus: (state, action) => {
            state.isStatus = action.payload;
        },
        setOrderId: (state, action) => {
            state.orderId = action.payload;
        },
        setParsedQueryString: (state, action) => {
            state.parsedQueryString = action.payload;
        },
        setRedirect: (state, action) => {
            state.redirect = action.payload;
        },
        setSearchLimit: (state, action) => {
            state.searchLimit = action.payload;
        }
    },
})

export const {
    addPreviousPageToken,
    setPageToken,
    clearPageTokens,
    setCompanyId,
    setIsSearchModalOpen,
    setIsShowingErrors,
    setOrderId,
    setParsedQueryString,
    setRedirect,
    setSearchLimit,
    setStatus,
} = searchSlice.actions;

export default searchSlice.reducer;
