import { useState, useEffect } from "react";


const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

const getBrowserDocumentHiddenProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

const getIsDocumentVisible = () => {
  return !document[getBrowserDocumentHiddenProp()];
}


export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible())
  const onVisibilityChange = () => setIsVisible(getIsDocumentVisible())

  useEffect(() => {
    const visibilityProp = getBrowserVisibilityProp()

    document.addEventListener(visibilityProp, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityProp, onVisibilityChange)
    }
  });

  return isVisible;
}
